window.htr_changeLanguage = function (language) {
  let pathArray = window.location.pathname.replace('/', '').split('/')
  if (pathArray.find(pathname => ['es', 'de', 'fr', 'it', 'ar', 'nl', 'pt', 'ja', 'zh', 'he'].includes(pathname))) {
    if (language === 'en') {
      pathArray.shift()
    } else {
      pathArray[0] = language
    }
  } else {
    if (language !== 'en') {
      pathArray.unshift(language)
    }
  }
  window.location.href = window.location.protocol + '//' + window.location.host + '/' + pathArray.join('/') + window.location.search
}
